import { useEffect, useState } from 'react';
import useFunctions from '../utils/functions';
import { ShowToast } from '../components/showToast';
import { useParams, useSearchParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import OneTrustImg from '../assets/onetrust.svg';
import OnePlanImg from '../assets/oneplan.svg';
import ThumbImg from '../assets/thumb.svg'

export default function Home() {
  const [statementInfo, setStatementInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { id } = useParams(); 
  const [searchParams] = useSearchParams(); 
  const days = searchParams.get('days');

  const { getSubscriberStatementDetails, getNextStatementDetails, sendDownloadFullStatementRequest } = useFunctions();

  const getNextPage = async (url) => {
    setLoading(true);
    const parsedUrl = new URL(url);
    const queryString = parsedUrl.search; 

    const {status, statement_data, response_message} = await getNextStatementDetails(queryString);

    if (status){
      setStatementInfo(statement_data);
      setLoading(false);
      return
    }else{
      console.log(response_message);
      ShowToast('error', response_message);
      setLoading(false);
    } 
  }

  const getStatements = async (id, days) => {
    setLoading(true);
    const {status, statement_data, response_message} = await getSubscriberStatementDetails(id, days, page);

    if (status){
      setStatementInfo(statement_data);
      setLoading(false);
      return
    }else{
      ShowToast('error', response_message);
      setLoading(false);
      return
    }
  }

  const downloadFullStatement = async () => {
    setLoading(true);
    const {status, response_message} = await sendDownloadFullStatementRequest();
    if (status){
      ShowToast("success", response_message)
      setLoading(false);
      return
    }else{
      ShowToast('error', response_message);
      setLoading(false);
      return
    }
  }

  const downloadStatement = () => {
    setLoading(true);
    const content = document.getElementById('statement-content');
    if (content) {
      import('html2pdf.js').then(html2pdf => {
        const opt = {
          margin: 1.5,
          filename: 'statement.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { scale: 1 },
          jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' }
        };
        html2pdf.default().from(content).set(opt).save();
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        console.error('Error loading html2pdf:', err);
        ShowToast('error', 'Failed to generate PDF. Please try again.');
      });
    } else {
      setLoading(false);
      console.error('Statement content not found');
      ShowToast('error', 'Statement content not found. Please try again.');
    }
  }

  useEffect(() => {
    getStatements(id, days);
  },[])

  return (
    <>
      {loading ? <div>Loading...</div> : 
        <>
          {statementInfo?.transactions?.length > 0 ? 
          <div className='relative'>
            <div id="statement-content" className='relative p-4'>
              <div className='flex flex-row gap-4 items-center'>
                <img src={OnePlanImg} alt="OnePlan" />
                <img src={OneTrustImg} alt="OneTrust" />
              </div>
              <hr className='border-gray'></hr>
              <div className='flex flex-col lg:flex-row md:flew-row justify-between mb-8 gap-4'>
                <div className='flex flex-col gap-2 mt-8 w-[100%] lg:w-[35%] md:w-[45%] initial-info-card'>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex description-box'>
                      <h1 className='description-box-text'>Start Date:</h1>
                    </div>
                    <div className='flex description-box-alt'>
                      <h1 className='description-box-text'>{statementInfo.start_date}</h1>
                    </div>
                  </div>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex description-box'>
                      <h1 className='description-box-text'>End Date:</h1>
                    </div>
                    <div className='flex description-box-alt'>
                      <h1 className='description-box-text'>{statementInfo.end_date}</h1>
                    </div>
                  </div>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex description-box'>
                      <h1 className='description-box-text'>Mobile Money No:</h1>
                    </div>
                    <div className='flex description-box-alt'>
                      <h1 className='description-box-text'>{statementInfo.mobile_no}</h1>
                    </div>
                  </div>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex description-box'>
                      <h1 className='description-box-text'>Currency:</h1>
                    </div>
                    <div className='flex description-box-alt'>
                      <h1 className='description-box-text'>Ghana Cedis</h1>
                    </div>
                  </div>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex description-box'>
                      <h1 className='description-box-text'>Opening Balance:</h1>
                    </div>
                    <div className='flex description-box-alt'>
                      <h1 className='description-box-text'>{statementInfo.opening_balance}</h1>
                    </div>
                  </div>
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex description-box'>
                      <h1 className='description-box-text'>Closing Balance:</h1>
                    </div>
                    <div className='flex description-box-alt'>
                      <h1 className='description-box-text'>{statementInfo.closing_balance}</h1>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-1 self-end'>
                  <h1 className='description-box-text'>Account Statement:</h1>
                  <h1 className='account-name'>{statementInfo.name}</h1>
                </div>
              </div>
              <hr className='border-gray'></hr>
              <DataTable value={statementInfo.transactions} rows={10} tableStyle={{ minWidth: '60rem' }}>
                <Column field="created_at" header="Transaction Date" style={{ width: '20%', borderBottom: '1px solid #ddd' }}></Column>
                <Column field="trans_type" header="Description" body={(rowData) => rowData.trans_type} style={{ width: '20%', borderBottom: '1px solid #ddd', textTransform: 'capitalize' }}></Column>
                <Column field="balance_type" header="Account" body={(rowData) => rowData.balance_type.toLowerCase()} style={{ width: '20%', borderBottom: '1px solid #ddd', textTransform: 'capitalize' }}></Column>
                <Column field="amount" header="Debits" style={{ width: '20%', borderBottom: '1px solid #ddd' }} body={(rowData) => rowData.trans_type === 'Debit' ? rowData.amount : '-'}></Column>
                <Column field="amount" header="Credit" style={{ width: '20%', borderBottom: '1px solid #ddd' }} body={(rowData) => rowData.trans_type === 'Credit' ? rowData.amount : '-'}></Column>
                <Column field="balance_after" header="Balance" style={{ width: '20%', borderBottom: '1px solid #ddd' }}></Column>
              </DataTable>
            </div>
            <div className='flex relative justify-center gap-4 mt-4'>
              <div className='flex flex-row justify-between gap-4 mb-2'>
                { statementInfo?.pagination?.prev_page_url ? 
                <>
                  <svg onClick={() => getNextPage(statementInfo?.pagination?.prev_page_url)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                  </svg>
                </> 
                : 
                <></>
                }
                
                <h1>Page {statementInfo?.pagination?.current_page} of {statementInfo?.pagination?.total_pages}</h1>
                { statementInfo?.pagination?.next_page_url ?
                  <svg onClick={() => getNextPage(statementInfo?.pagination?.next_page_url)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                  </svg>
                :
                <></>
                }
                <div className='hidden flex flex-row gap-2 mb-12'>
                  <div className="cursor-pointer group relative flex" onClick={() => downloadStatement()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
                    </svg>
                    <span class="absolute top-7 scale-0 rounded tooltip-element-bg p-2 text-xs text-black group-hover:scale-100">
                      <h1 className='tooltip-text tooltip-text-alt tooltip-text-color-alt'>
                        Download
                      </h1>
                    </span>
                  </div>
                  <div className="cursor-pointer group relative flex" onClick={() => downloadFullStatement()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m-6 3.75 3 3m0 0 3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
                    </svg>
                    <span class="absolute top-7 scale-0 rounded tooltip-element-bg p-2 text-xs text-black group-hover:scale-100">
                      <h1 className='tooltip-text tooltip-text-alt tooltip-text-color-alt'>
                        Download All
                      </h1>
                    </span>
                  </div> 
                </div>
              </div>
              <img src={ThumbImg} alt="Thumb" className='absolute bottom-0 right-0 img-width' />
            </div>
            <div className='footer'>
              <div className='flex flex-col gap-2'>
                <p className='footer-text'>You must inform One Plan on any discrepancies observed in this statement.</p>
                <p className='footer-text'>All products are subject to One Plan terms and conditions.</p>
                <p className='footer-text'>Call center number + 233 (0) 308 251 056</p>
                <p className='footer-text'>Email: help@oneplan.finance</p>
              </div>
            </div>
          </div> : <div>No statements found</div>}
        </>
      }
    </>
  )
}