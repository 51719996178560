import useAxios from "../hooks/hook"

const useFunctions = () => {
  const { executeGet, executeReq } = useAxios();

  const getSubscriberStatementDetails = async (id, days, page) => {
    const {data} = await executeGet(`statements?subscriber=${id}&days=${days}&page=${page}`)
    if (data.status){
      return {status: data.status, statement_data: data.data, response_message: data.message}
    }else{
      return {status: data.status, response_message: data.message}
    }
  }

  const sendDownloadFullStatementRequest = async () => {
    const {data} = await executeReq(`statements`)
    if (data.status){
      return {status: data.status, response_message: data.message}
    }else{
      return {status: data.status, response_message: data.message}
    }
  }

  const getNextStatementDetails = async (url) => {
    const {data} = await executeGet(`statements${url}`)
    if (data.status){
      return {status: data.status, statement_data: data.data, response_message: data.message}
    }else{
      return {status: data.status, response_message: data.message}
    }
  }

  return { getSubscriberStatementDetails, getNextStatementDetails, sendDownloadFullStatementRequest }

}

export default useFunctions