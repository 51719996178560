import './App.css';
import {Route, Routes, Navigate} from 'react-router-dom';
import HomePage from './pages/home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/statements/:id" element={<HomePage />}></Route>
        <Route path="/" element={<Navigate to="/statements/1" replace />} />
      </Routes>
    </div>
  );
}

export default App;
